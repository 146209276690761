<template>
	<div class="client-list list-view">
		<div class="list-header">
			<div class="col">
				<p>Clients</p>
			</div>
			<div class="col span-2 last">
				<form id="search-items">
					<input type="text" placeholder="Search" v-model="clientNameSearchQuery" />
					<button class="submit" type="submit"><i class="fas fa-search"></i></button>
				</form>
			</div>
		</div>
		<div class="grouping" v-for="(value, letter) in sortedClients" v-bind:key="letter" v-bind:data-letter="letter">
			<div class="col">
				<div class="heading">
					<div class="letter" v-bind:id="letter">
						<p>{{ letter }}</p>
					</div>
				</div>
				<!-- end heading -->
			</div>
			<!-- end col -->
			<div class="col span-3">
				<div class="clients items">
					<template v-for="clientName in value" v-bind:key="clientName.slug">
						<article class="client item grid-container">
							<div class="col">
								<router-link :to="{path: `/client/${clientName.slug}/dashboard/`}">
									<p class="client-name item-name">{{ clientName.clientName }}</p>
								</router-link>
							</div>
							<!-- end col -->
							<div v-if="can(getAuthData, 'update_own_client_settings')">
								<div class="col last edit">
									<button class="edit" title="Edit client">
										<router-link :to="{path: `/admin/clients/edit/${clientName.slug}`}">
											<i class="fad fa-cog"></i>
										</router-link>
									</button>
								</div>
							</div>
							<!-- end col -->
						</article>
					</template>
				</div>
				<!-- end clients -->
			</div>
			<!-- end col -->
		</div>
	</div>
	<!-- end client-list -->
</template>
<script>
import axios from "axios"
import {mapGetters} from "vuex"
export default {
	data() {
		return {
			sortedClients: {}, //use to order clients by letter
			clientNameSearchQuery: "",
		}
	},
	created() {
		this.getClients()
		this.getDeletedClients()
	},
    watch:{
        clientNameSearchQuery(){
            this.searchClientsByClientName()
        }
    },
	methods: {
        searchClientsByClientName(){
            this.getClients(this.clientNameSearchQuery);
        },
		async getClients(optionalClientNameSearchQuery = undefined) {
            
            // Clear the array
            this.clientsArray = [];
			
            try {
				const params = new URLSearchParams()
				// params.append('JWT', this.$store.getters.getToken);
				// params.append('RFT', this.$store.getters.getRefreshToken);

				const response = await axios.get("/api/clients/findAllClients", params, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				})
				// JSON responses are automatically parsed.
				this.clientsArray = response.data
				//console.log('clientsarray', this.clientsArray);
				this.sortClients(optionalClientNameSearchQuery)
			} catch (error) {
				console.log("oops an error", error)
			}
		},
		async getDeletedClients() {
            
            // Clear the array
            this.deletedClientsArray = [];
			
            try {
				const params = new URLSearchParams()
				// params.append('JWT', this.$store.getters.getToken);
				// params.append('RFT', this.$store.getters.getRefreshToken);

				const response = await axios.get("/api/clients/findDeletedClients", params, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				})
				// JSON responses are automatically parsed.
				this.deletedClientsArray = response.data
			} catch (error) {
				console.log("oops an error", error)
			}
		},
		sortClients(optionalClientNameSearchQuery = undefined) {


            // Clear the object
            this.sortedClients = {};

			//get the first letter of client name
			function findFirstLetter(string) {
				return string.charAt(0)
			}

            // If we are provided an optional search query
            if(optionalClientNameSearchQuery){
                // Filter the clientsArray based on a partial string match
                this.clientsArray = this.clientsArray.filter((client)=>client.clientName.toLowerCase().includes(optionalClientNameSearchQuery.toLowerCase()));
            }

			// Sort by alphabetical order
			let tempClients = this.clientsArray
			tempClients = tempClients.sort((a, b) => {
				let fa = a.clientName.toLowerCase(),
					fb = b.clientName.toLowerCase()
				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})

			//sort array items under each letter
			tempClients.forEach((clientName) => {
				if (this.getUserClientRelationship(this.getAuthData, clientName.slug) || this.can(this.getAuthData, "read_other_client_data")) {
					let clientFirstLetter = findFirstLetter(clientName.clientName)
					if (this.sortedClients[clientFirstLetter]) {
						this.sortedClients[clientFirstLetter] = [...this.sortedClients[clientFirstLetter], clientName]
					} else {
						this.sortedClients[clientFirstLetter] = [clientName]
					}
				}
			})

			//console.log(this.sortedClients)
		},
	},
	computed: {
		...mapGetters("auth", ["getAuthData"]),
	},
}
</script>
